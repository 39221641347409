import './style.css';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import gsap from 'gsap'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/src/geometries/TextGeometry';

// TEXT INFORMATION //

const mercuryText = "Mercury is the smallest planet in our solar system and the one nearest to the Sun. The quickest planet, Mercury, completes one orbit of the Sun in 88 days on Earth"
const mercuryFacts = "<span class=\"blue-text\">Mass:</span> 0.330 × 10^24 kg<br><br><span class=\"blue-text\">Diameter:</span> 4,879 km<br><br><span class=\"blue-text\">Surface Temperature:</span> 800°F<br><br><span class=\"blue-text\">Surface Gravitational Strength: </span>3.7 m/s²<br><br><span class=\"yellow-text\">Did you know?</span><br><br>Despite it's proximity to the Sun, Mercury’s surface has ice on it"

const marsText = "Mars is a cold, arid, dusty planet with a tenuous atmosphere. There is compelling evidence that Mars had a thicker atmosphere and was wetter and warmer billions of years ago"
const marsFacts = "<span class=\"blue-text\">Mass:</span> 6.39 × 10^23 kg<br><br><span class=\"blue-text\">Diameter:</span> 6,779 km<br><br><span class=\"blue-text\">Surface Temperature:</span> -80°F<br><br><span class=\"blue-text\">Surface Gravitational Strength:</span> 3.7m/s²<br><br><span class=\"yellow-text\">Did you know?</span><br><br>Mars has 2 moons called Deimos and Phobos"

const venusText = "Venus is the hottest planet in our solar system due to a thick atmosphere that traps heat in a runaway greenhouse effect. It has a very acidic surface which is constantly being battered by sulphuric acid clouds"
const venusFacts = "<span class=\"blue-text\">Mass:</span> 4.87 × 10^24 kg<br><br><span class=\"blue-text\">Diameter:</span> 12,104 km<br><br><span class=\"blue-text\">Surface Temperature:</span> 900°F<br><br><span class=\"blue-text\">Surface Gravitational Strength:</span> 8.87 m/s²<br><br><span class=\"yellow-text\">Did you know?</span><br><br>Unlike the other planets in our solar system, Venus spins clockwise on its axis"

const earthText = "Our home planet, Earth, is the only place we are currently aware of where there are living organisms. Moreover, it is the only planet in our solar system with surface water"
const earthFacts = "<span class=\"blue-text\">Mass:</span> 5.97 × 10^24 kg<br><br><span class=\"blue-text\">Diameter:</span> 12,756 km<br><br><span class=\"blue-text\">Surface Temperature:</span> 57°F<br><br><span class=\"blue-text\">Surface Gravitational Strength:</span> 9.80 m/s²<br><br><span class=\"yellow-text\">Did you know?</span><br><br>A very interesting lifeform has been discovered on this planet. They go by the name \"Humans\""

const jupiterText = "Jupiter is a massive gaseous planet which is more than twice as big as all of the other planets in the solar system combined. The Big Red Spot on the enormous planet is a centuries-old storm that is larger than the Earth"
const jupiterFacts = "<span class=\"blue-text\">Mass:</span> 1.89 × 10^24 kg<br><br><span class=\"blue-text\">Diameter:</span> 142,984 km<br><br><span class=\"blue-text\">Surface Temperature:</span> -238°F<br><br><span class=\"blue-text\">Surface Gravitational Strength:</span> 24.79 m/s²<br><br><span class=\"yellow-text\">Did you know?</span><br><br>Jupiter is the fastest spinning planet in the solar system, boasting a rotational velocity of 12.6 km/s (~7.45 m/s) or 45,300 km/h (28,148 mph)"

const saturnText = "Although Saturn has an interesting gaseous atmosphere mainly comprised of Helium, is distinctive in our solar system because of its brilliant, intricate system of ice rings. While the rings of the other large planets are impressive, none compare to those of Saturn"
const saturnFacts = "<span class=\"blue-text\">Mass:</span> 5.68 × 10^24 kg<br><br><span class=\"blue-text\">Diameter:</span> 120,536 km<br><br><span class=\"blue-text\">Surface Temperature:</span> -285°F<br><br><span class=\"blue-text\">Surface Gravitational Strength:</span> 10.44 m/s²<br><br><span class=\"yellow-text\">Did you know?</span><br><br>Saturn has more moons than any other planet in the Solar System, boasting a whopping 82 confirmed moons"

const uranusText = "Uranus has a gaseous atmosphere and a small rocky surface underneath the gas. It rotates over 90 degrees away from the equator, and thus appears to rotate on its side because of this peculiar tilt"
const uranusFacts = "<span class=\"blue-text\">Mass:</span> 86.8 × 10^24 kg<br><br><span class=\"blue-text\">Diameter:</span> 51,118 km<br><br><span class=\"blue-text\">Surface Temperature:</span> -353°F<br><br><span class=\"blue-text\">Surface Gravitational Strength:</span> 8.87 m/s²<br><br><span class=\"yellow-text\">Did you know?</span><br><br>Although Uranus is closer to the Sun than Neptune, it is the coldest planet in the Solar System"

const neptuneText = "The eighth and farthest giant planet from our Solar, Neptune is chilly, dark, and buffeted by supersonic winds. It was the first planet to be discovered using calculations"
const neptuneFacts = "<span class=\"blue-text\">Mass:</span> 1.02 × 10^24 kg<br><br><span class=\"blue-text\">Diameter:</span> 49,528 km<br><br><span class=\"blue-text\">Surface Temperature:</span> -373°F<br><br><span class=\"blue-text\">Surface Gravitational Strength:</span> 11.15 m/s²<br><br><span class=\"yellow-text\">Did you know?</span><br><br>Neptune's winds are the craziest in the whole Solar System. Hurricane winds on Neptune reach upwards of 2,100 km/hr"

const sunText = "The Sun is the star at the center of our Solar System. It's what provides our Solar System with heat and light. It is very large and extremely hot. It's surface is a vicious mix of fire, lava and magma."
const sunFacts = "<span class=\"blue-text\">Mass:</span> 1.989 x 10^30 kg<br><br><span class=\"blue-text\">Diameter:</span> 1,392,680 km<br><br><span class=\"blue-text\">Surface Temperature:</span> 9,940°F<br><br><span class=\"blue-text\">Surface Gravitational Strength:</span> 274 m/s²<br><br><span class=\"yellow-text\">Did you know?</span><br><br>The Sun's core can reach temperatures of up to 27 million degrees farenheit. This is because the sun's core is constantly undergoing fusion reactions to create Helium"


var textArray = [sunText, mercuryText, venusText, earthText, marsText, jupiterText, saturnText, uranusText, neptuneText]
var factArray = [sunFacts, mercuryFacts, venusFacts, earthFacts, marsFacts, jupiterFacts, saturnFacts, uranusFacts, neptuneFacts]

// Canvas
const canvas = document.querySelector('canvas.webgl');

// Sizes 
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
};

// Event Listeners

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2)) // MIGHT WANT TO REMOVE FOR OPTIMIZATION?
})

document.getElementById('container')
    .addEventListener('click', function () {
        mouseWasPressed = true;
    });

// Scene
const scene = new THREE.Scene()

// Object Definitions
const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();
var mouseWasPressed = false;
var movementAllowed = true;
var cameraPositionPreMove = new THREE.Vector3()
document.getElementById("backButton").disabled = true;
document.getElementById("backButton").style.display = "none";
const loader = new FontLoader();
var initComplete = false;

var isInDetails = false;

window.addEventListener('mousemove', function (e) {
    mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
    mouse.y = - (e.clientY / window.innerHeight) * 2 + 1;
});

// Texture Loading

const sunTexture = new THREE.TextureLoader().load('textures/sun.jpg');
const sunMaterial = new THREE.MeshBasicMaterial({ map: sunTexture });

const mercuryTexture = new THREE.TextureLoader().load('textures/mercury.jpg');
const mercuryMaterial = new THREE.MeshBasicMaterial({ map: mercuryTexture });

const venusTexture = new THREE.TextureLoader().load('textures/venus.jpg');
const venusMaterial = new THREE.MeshBasicMaterial({ map: venusTexture });

const earthTexture = new THREE.TextureLoader().load('textures/earth.jpg');
const earthMaterial = new THREE.MeshBasicMaterial({ map: earthTexture });

const marsTexture = new THREE.TextureLoader().load('textures/mars.jpg');
const marsMaterial = new THREE.MeshBasicMaterial({ map: marsTexture });

const jupiterTexture = new THREE.TextureLoader().load('textures/jupiter.jpg');
const jupiterMaterial = new THREE.MeshBasicMaterial({ map: jupiterTexture });

const saturnTexture = new THREE.TextureLoader().load('textures/saturn.jpg');
const saturnMaterial = new THREE.MeshBasicMaterial({ map: saturnTexture });

const uranusTexture = new THREE.TextureLoader().load('textures/uranus.jpg');
const uranusMaterial = new THREE.MeshBasicMaterial({ map: uranusTexture });

const neptuneTexture = new THREE.TextureLoader().load('textures/neptune.jpg');
const neptuneMaterial = new THREE.MeshBasicMaterial({ map: neptuneTexture });

const starTexture = new THREE.TextureLoader().load('textures/stars.jpg');
const starMaterial = new THREE.MeshBasicMaterial({ map: starTexture, side: THREE.DoubleSide });

// Orbit Color
const orbitColor = new THREE.MeshBasicMaterial({ color: 0xFFFFFF, transparent: true, opacity: 0.25 })

// Star Object
const starGeom = new THREE.SphereGeometry(5000, 64, 64)
const stars = new THREE.Mesh(starGeom, starMaterial)
scene.add(stars)

// Planet Objects
const sunGeom = new THREE.SphereGeometry(100, 64, 64)
const sun = new THREE.Mesh(sunGeom, sunMaterial)
sun.userData = "SUN"
scene.add(sun)

const mercuryGeom = new THREE.SphereGeometry(12 , 64, 64)
const mercury = new THREE.Mesh(mercuryGeom, mercuryMaterial)
mercury.userData = "MERCURY"
scene.add(mercury)

const venusGeom = new THREE.SphereGeometry(18, 64, 64)
const venus = new THREE.Mesh(venusGeom, venusMaterial)
venus.userData = "VENUS"
scene.add(venus)

const earthGeom = new THREE.SphereGeometry(18, 64, 64)
const earth = new THREE.Mesh(earthGeom, earthMaterial)
earth.userData = "EARTH"
scene.add(earth)

const marsGeom = new THREE.SphereGeometry(15, 64, 64)
const mars = new THREE.Mesh(marsGeom, marsMaterial)
mars.userData = "MARS"
scene.add(mars)

const jupiterGeom = new THREE.SphereGeometry(30, 64, 64)
const jupiter = new THREE.Mesh(jupiterGeom, jupiterMaterial)
jupiter.userData = "JUPITER"
scene.add(jupiter)

const saturnGeom = new THREE.SphereGeometry(25, 64, 64)
const saturn = new THREE.Mesh(saturnGeom, saturnMaterial)
saturn.userData = "SATURN"
scene.add(saturn)

const uranusGeom = new THREE.SphereGeometry(20, 64, 64)
const uranus = new THREE.Mesh(uranusGeom, uranusMaterial)
uranus.userData = "URANUS"
scene.add(uranus)

const neptuneGeom = new THREE.SphereGeometry(19, 64, 64)
const neptune = new THREE.Mesh(neptuneGeom, neptuneMaterial)
neptune.userData = "NEPTUNE"
scene.add(neptune)

const spaceObjects = [sun, mercury, venus, earth, mars, jupiter, saturn, uranus, neptune] // Used in the detection of click on a space object
const planetArray = [mercury, venus, earth, mars, jupiter, saturn, uranus, neptune] // Used in the manipulation of planet positions AND the creation of orbits

// Orbit Objects
var orbitObjects = []
var orbitMeshes = []

for (let x = 0; x < planetArray.length; x++) { // Create a bunch of orbit circular geometries
    orbitObjects.push(new THREE.CircleGeometry(240 + (x * 60), 100))
}

for (let x = 0; x < planetArray.length; x++) { // Create orbit meshes and add them into the scene
    orbitObjects[x].vertices.shift();
    orbitObjects[x].vertices.push(orbitObjects[x].vertices[0]);
    orbitMeshes.push(new THREE.Line(orbitObjects[x], orbitColor))
    scene.add(orbitMeshes[x])
    orbitMeshes[x].rotation.x = 1.5708

}

// Cameras
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 7500)
camera.position.z = 450
camera.position.y = 350
scene.add(camera)
camera.layers.enableAll();

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true;
controls.enableZoom = true;
controls.enablePan = false;
controls.maxDistance = 1500;
controls.minDistance = 250;

// Renderers
const renderer = new THREE.WebGLRenderer({
    canvas: canvas, antialias: true, alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2)); // Adjust resolution for different pixel ratios
renderer.autoClear = false;
renderer.sortObjects = false;
renderer.setClearColor(0x000000);

// Lights


// Animations
const clock = new THREE.Clock()

var mercuryAngle = 0
var venusAngle = 0
var earthAngle = 0
var marsAngle = 0
var jupiterAngle = 0
var saturnAngle = 0
var uranusAngle = 0
var neptuneAngle = 0

var planetAngles = [mercuryAngle, venusAngle, earthAngle, marsAngle, jupiterAngle, saturnAngle, uranusAngle, neptuneAngle] // Used in the movement of planets

const geometry = new THREE.BoxGeometry( 1, 1, 1 );
const material = new THREE.MeshBasicMaterial( {color: 0x00ff00} );
const cube = new THREE.Mesh( geometry, material );
scene.add( cube );

function writeText(object) {
    for (var i = 0; i < spaceObjects.length; i++) {
        if (object.userData == spaceObjects[i].userData) {
            document.getElementById('text').innerHTML = textArray[i];
            document.getElementById('text2').innerHTML = factArray[i];
            document.getElementById('title2').innerHTML = object.userData
        }
    }
}

function postMovement(object) {
    for (var i = 0; i < scene.children.length; i++) {
        scene.children[i].visible = false
        if (object.userData == scene.children[i].userData) {
            scene.children[i].visible = true
        }
    }
    writeText(object);
    document.getElementById("backButton").disabled = false;
    document.getElementById("backButton").style.display = "block";
    document.getElementById('help').innerHTML = "";
    controls.enabled = false;
    isInDetails = true;
}


function moveCameraToPlanet(object) {
    
    var vec3 = new THREE.Vector3();

    cameraPositionPreMove.copy(camera.position)

    if (object.userData == "SUN") {
        vec3.copy(pointBetweenCameraAndSun(camera.position.clone()))
    }
    
    else { 
        vec3.copy(getNewCameraPoint(object))        
    }

    document.getElementById('help').innerHTML = "";

    gsap.to(camera.position, {
        onStart: movementAllowed = false,
        x: vec3.x,
        y: vec3.y,
        z: vec3.z,
        ease: "power1.inOut",
        duration: 1.5,
        onComplete: postMovement,
        onCompleteParams: [object]
    }); 
}

function postReset() {
    movementAllowed = true;
    controls.enabled = true;
    isInDetails = false;
}

function moveCameraBack() {
    movementAllowed = true
    document.getElementById("backButton").disabled = true;
    document.getElementById("backButton").style.display = "none";
    document.getElementById('text').innerHTML = "";
    document.getElementById('text2').innerHTML = "";
    document.getElementById('title2').innerHTML = "";
    
    for (var i = 0; i < scene.children.length; i++) {
        scene.children[i].visible = true
    }

    gsap.to(camera.position, {
        onStart: movementAllowed = false,
        x: cameraPositionPreMove.x,
        y: cameraPositionPreMove.y,
        z: cameraPositionPreMove.z,
        ease: "power1.inOut",
        duration: 1.5,
        onComplete: postReset
    });
}

function pointBetweenCameraAndSun(cameraPos) {
    var point = new THREE.Vector3
    point.copy(sun.position)

    cameraPos.normalize()
    point.add(cameraPos.multiplyScalar(3 * sun.geometry.boundingSphere.radius))

    return point
}

function getNewCameraPoint(object) {
    var point = new THREE.Vector3
    var normalizedObjectPos = new THREE.Vector3
 
    point.copy(object.position)
    normalizedObjectPos.copy(object.position)
    normalizedObjectPos.normalize()
    normalizedObjectPos.multiplyScalar(object.geometry.boundingSphere.radius * 3)

    point.add(normalizedObjectPos)
    return point
}

// let textGeo

// loader.load( 'fonts/helvetiker_regular.typeface.json', function ( font ) {
// 	textGeo = new TextGeometry( 'Hello three.js!', {
// 		font: font,
// 		size: 100,
// 		height: 50,
// 		curveSegments: 12,
// 		bevelEnabled: true,
// 		bevelThickness: 10,
// 		bevelSize: 8,
// 		bevelOffset: 0,
// 		bevelSegments: 5
// 	} );
// } );

// var textMesh1 = new THREE.Mesh( textGeo, material );

// scene.add(textMesh1)

const tick = () => { 
    sun.rotation.y += 0.001

    document.getElementById('backButton').onclick = function() {
        moveCameraBack()
    };

    if (movementAllowed) {
        planetAngles[0] += 0.003502
        planetAngles[1] += 0.002978
        planetAngles[2] += 0.0024077
        planetAngles[3] += 0.001307
        planetAngles[4] += 0.000969
        planetAngles[5] += 0.000681
        planetAngles[6] += 0.000543
        planetAngles[7] += 0.000474
    }

    if (!movementAllowed) {
        for (var i = 0; i < planetArray.length; i++) {
            planetArray[i].rotation.y += 0.005
        }
    }

    for (var i = 0; i < planetArray.length; i++) {
        planetArray[i].position.x = Math.cos(planetAngles[i]) * (240 + (i * 60))
        planetArray[i].position.z = Math.sin(planetAngles[i]) * (240+ (i * 60))
    }

    // Raycasting & Clicking

    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(spaceObjects)
    if (!initComplete) {
        //Create labels here?
    }

    if (intersects.length > 0) {
        { document.body.style.cursor = 'pointer' } // Change cursor to pointer when hovering over node
    }

    else {
        { document.body.style.cursor = 'default' } // Change cursor back to default when not hovering over node
    }

    if (mouseWasPressed == true && intersects.length > 0 && isInDetails == false) {
        if (intersects[0].object.userData == "SUN") {
            moveCameraToPlanet(sun)
        }
        if (intersects[0].object.userData == "MERCURY") {
            moveCameraToPlanet(mercury)
        }
        if (intersects[0].object.userData == "VENUS") {
            moveCameraToPlanet(venus)
        }
        if (intersects[0].object.userData == "EARTH") {
            moveCameraToPlanet(earth)
        }
        if (intersects[0].object.userData == "MARS") {
            moveCameraToPlanet(mars)
        }
        if (intersects[0].object.userData == "JUPITER") {
            moveCameraToPlanet(jupiter)
        }
        if (intersects[0].object.userData == "SATURN") {
            moveCameraToPlanet(saturn)
        }
        if (intersects[0].object.userData == "URANUS") {
            moveCameraToPlanet(uranus)
        }
        if (intersects[0].object.userData == "NEPTUNE") {
            moveCameraToPlanet(neptune)
        }
    }

    controls.update();
    scene.updateMatrixWorld();
    renderer.clear();

    renderer.render(scene, camera);

    mouseWasPressed = false;

    // mercuryDiv.style.zIndex = 105;

    // Call tick again on the next fram
    window.requestAnimationFrame(tick)
}

tick()